/* Text */

p {
  line-height: 1.4em;
  margin: 0.7em 0;
}

blockquote {
  border-left: 0.3em solid grey;
  margin: 0.35em 0 0.35em 1em;
  padding: 0.35em 0 0.35em 0.7em;
}

/* Lightmode Links */

a,
.links a,
.alldaposts .links a.comments {
  color: #2c62a8;
}

a:hover,
.links a:hover,
.alldaposts .links a.comments:hover {
  color: #3a7fd9;
}

#th-menu a.pure-menu-link:hover,
#th-menu li[active="true"] a,
#th-menu li[active] a {
  background-color: #3a7fd9;
}

.cw-items a:hover {
  color: #b99cd2;
}

.bottombar a {
  color: #888;
}

.bottombar a.reply-comment {
  color: #2c62a8;
}

.bottombar a.reply-comment:hover {
  color: #3a7fd9;
}

.ucount a {
  color: #6c4290;
}

.ucount a:hover {
  color: #9658cc;
}

/* Darkmode Links */

body.dark a,
body.dark div.author a,
body.dark div.author a.authorlink,
body.dark .alldaposts .links a.comments,
body.dark .sidebar ul.top5 li a {
  color: #49677f;
}

body.dark a:hover,
body.dark .links a:hover,
body.dark div.author a:hover,
body.dark div.author a.authorlink:hover,
body.dark #sortbuttons a:hover,
body.dark .alldaposts .links a.comments:hover,
body.dark .sidebar ul.top5 li a:hover {
  color: #3a7fd9;
}

body.dark .cw-items a:hover {
  color: #b99cd2;
}

body.dark .bottombar a {
  color: #414141;
}

body.dark .bottombar a.reply-comment {
  color: #49677f;
}

body.dark .bottombar a.reply-comment:hover {
  color: #3a7fd9;
}

body.dark .ucount a {
  color: #644c7b;
}

body.dark .ucount a:hover {
  color: #9658cc;
}

/* Lightmode Alerts */

.comments article.comment.highlight {
  background-color: #fff3c9;
}

article.pmessage.post.newmsg {
  border-left: 5px solid #f5844c;
}

.content .hasmail path {
  fill: #6c4290;
}

span.mailcount {
  background: #f5844c;
}

.glyphbutton a:hover {
  color: black;
}

.stick {
  color: #369054;
}

.nsfw {
  color: #be3636;
  border: 1px solid #be3636;
}

.red-confirm {
  color: #be3636;
}

.links a.post-open-reports {
  color: #be3636;
}

.links a.post-open-reports:hover {
  color: #ec3a3a;
}

.alert.div-message,
.alert.div-error {
  color: #ffffff;
  margin: 0.5em 0;
  padding: 0.5em;
}

.alert.div-message {
  background: #369054;
}

.alert.div-error {
  background: #be3636;
}

.announcement {
  background-color: #da7a4d;
  padding: 3px 0.5em;
}

/* Darkmode Alerts */

body.dark .comments article.comment.highlight {
  background-color: #191919;
}

body.dark article.pmessage.post.newmsg {
  border-left: 5px solid #8c4d32;
}

body.dark span.mailcount {
  background: #f5844c;
}

body.dark .glyphbutton a:hover {
  color: black;
}

body.dark .stick {
  color: #436f51;
}

body.dark .nsfw {
  color: #8a3b3b;
  border: 1px solid #8a3b3b;
}

body.dark .red-confirm {
  color: #8a3b3b;
}

body.dark .links a.post-open-reports {
  color: #8a3b3b;
}

body.dark .links a.post-open-reports:hover {
  color: #e95151;
}

body.dark .alert.div-message {
  background: #436f51;
}

body.dark .alert.div-error {
  background: #8a3b3b;
}

body.dark .announcement {
  background-color: #8c4d32;
}

/* Header */

.th-navbar {
  background-color: #6c4290;
  height: 3.8em;
}

.pure-menu a svg.logo {
  height: 2.8em;
  margin-top: 6px;
}

.motto {
  z-index: 20;
  position: absolute;
  bottom: 1px;
  left: 4.4em;
  color: white;
  font-size: smaller;
}

body.dark .th-l-stroke[stroke="#fff"] {
  stroke: #644c7b;
}

body.dark .th-l-fill[fill="#fff"] {
  fill: #644c7b;
}

span.mailcount {
  position: absolute;
  top: 14px;
  right: -8px;
  line-height: 1.2em;
  min-width: 1.35em;
  border-radius: 1.25em;
  font-size: small;
  display: inline-block;
  text-align: center;
  padding: 0.05em 0.12em;
  margin-right: 3px;
}

#th-uinfo {
  text-align: right;
  padding: 0 1em;
}

/* Sub bar */

.th-subbar {
  background-color: black;
  border-bottom: 1px solid black;
  font-variant: small-caps;
  overflow: hidden;
  height: 1.8em;
  line-height: 1.6em;
}

#subsdropdown {
  vertical-align: inherit;
}

body.dark .th-subbar {
  background-color: #000;
  border-bottom: 1px solid #644c7b;
}

body.dark .th-subbar a:hover {
  color: #9658cc;
}

#postscore {
  color: white;
}

/* SIDEBAR STYLES */

.sidebar {
  padding: 1em 10px 10px 10px;
  border-left: solid 1px #ddd;
}

.subinfo h3 {
  font-size: 1.5em;
  margin: 1em 0 0.5em;
}

.sidebar ul {
  padding-left: 1em;
  margin-left: 10px;
}

.subs {
  text-align: center;
}

.sidebar .pure-button-group {
  text-align: center;
}

.subinfo a {
  color: #6c4290;
}

.subinfo a:hover {
  color: #9658cc;
}

body.dark .subinfo a {
  color: #644c7b;
}

body.dark .subinfo a:hover {
  color: #9658cc;
}

body.dark .moderators .i-icon svg {
  fill: #666;
}

/* Posts */

.post .title:hover,
.post a.title:hover {
  color: #9658cc;
}

.post .title:visited {
  color: #6c4290;
}

.p-icon[data-icon="exclaim"] path {
  fill: #6c4290;
}

body.dark .post .title:hover,
body.dark .post a.title:hover {
  color: #9658cc;
}

body.dark .post .title:visited {
  color: #644c7b;
}

body.dark .p-icon[data-icon="exclaim"] path {
  fill: #644c7b;
}

#hiddensubs li a {
  color: #6c4290;
}

.stickyposts {
  margin: 0 0 0.5em 0;
  padding: 0.5em 0 0 0;
  border-bottom: 1px solid #ddd;
}

body.dark .stickyposts {
  border-bottom: 1px dashed #545454;
}

.pblock.level .uprofile-level-box .fill {
  background-color: #6c4290;
}

body.dark .pblock.level .uprofile-level-box .fill {
  background-color: #644c7b;
}

.post-heading h1 {
  font-size: large;
  margin: 0;
  font-weight: normal;
  display: inline-block;
}

.markdown-editor textarea {
  padding: 0.5em;
  margin: 0 0 5px 0;
}

/* User Page */

.pblock.level .inset .levelNo {
  line-height: 60px;
  font-size: 60px;
  height: 60px;
}

/* Votes */

.c-upvote svg,
.c-downvote svg,
.upvote svg,
.downvote svg {
  fill: #bdbdbd;
}

.upvoted path {
  fill: #9658cc;
}

.downvoted path {
  fill: #3a7fd9;
}

.c-upvote svg:hover,
.upvote svg:hover {
  stroke: #9658cc;
}

.c-downvote svg:hover,
.downvote svg:hover {
  stroke: #3a7fd9;
}

/* Expandos */

body.dark .pure-control-group label,
body.dark .form label {
  color: #644c7b;
}

/* Lines */

hr {
  border-bottom: 1px dashed #a6a6a6;
}

body.dark hr {
  border-bottom: 1px dashed #545454;
}

.pchild {
  border-left: 1px dashed #cecece;
}

body.dark .pchild {
  border-left: 1px dashed #545454;
}

/* Direct Messages */

.pmessage.post p.title {
  border-bottom: 1px solid #c8c8c8;
}

body.dark .pmessage.post p.title {
  border-bottom: 1px solid #545454;
}

.pmessage p.container a {
  color: black;
}

.pmessage p.container a:hover {
  background: #9658cc;
  color: #ffffff;
}

body.dark .pmessage.post p.container a {
  background-color: rgba(51, 51, 51, 0.5);
  color: white;
}

body.dark .pmessage.post p.container a:hover {
  background-color: rgba(51, 51, 51, 0.8);
  color: white;
}

.pmessage p.container a.deletedmsg,
.pmessage p.container a.deletedmsg:hover {
  background: #be3636;
  color: #ffffff;
}

body.dark .pmessage p.container a.deletedmsg,
body.dark .pmessage p.container a.deletedmsg:hover {
  background: #8a3b3b;
  color: #ffffff;
}

.pmessage p.container a.savedmsg,
.pmessage p.container a.savedmsg:hover {
  background: #369054;
  color: #ffffff;
}

body.dark .pmessage p.container a.savedmsg,
body.dark .pmessage p.container a.savedmsg:hover {
  background: #436f51;
  color: #ffffff;
}

/* other stuff? */

body.dark .editbar path {
  fill: #9165b6;
}

/* Poll styles */

.poll-pbar > div {
  background-color: #666;
  height: 1.25em;
}

.poll-pbar.poll-voted > div {
  background-color: #9658cc;
}

.poll-pbar.poll-voted {
  border-color: #b99cd2;
}

body.dark .poll-pbar.poll-voted > div {
  background-color: #7b6191;
}

/* Buttons */

.newmsgcount .i-icon svg,
.newmsgcount .p-icon path {
  fill: black;
}

.button-secondary .p-icon path,
body.dark .newmsgcount .i-icon svg,
body.dark .newmsgcount .p-icon path {
  fill: white;
}

pure-button,
a.pure-button {
  color: black;
}

pure-button:hover,
a.pure-button:hover {
  background-color: #eeeeee;
  color: black;
  background-image: none;
}

.sidebar .button-secondary {
  background: #101010;
  color: #fff;
}

.sidebar .button-secondary:hover {
  background: #282828;
  color: #fff;
}

/* Header Buttons */

.th-navbar .pure-button-primary,
.th-navbar a.pure-button-primary {
  background-color: #6c4290;
  color: #fff;
}

.th-navbar .pure-button-primary:hover,
.th-navbar a.pure-button-primary:hover {
  background-color: #9c60d1;
  color: #fff;
  background-image: none;
}

.th-navbar .button-secondary {
  background-color: black;
  color: #fff;
}

.th-navbar .button-secondary:hover {
  background-color: #3b244f;
  color: #fff;
  background-image: none;
}

body.dark .th-navbar .pure-button-primary,
body.dark .th-navbar a.pure-button-primary {
  background-color: black;
  color: #fff;
}

body.dark .th-navbar .pure-button-primary:hover,
body.dark .th-navbar a.pure-button-primary:hover {
  background-color: #9658cc;
  color: #fff;
  background-image: none;
}

body.dark .th-navbar .button-secondary:hover {
  color: #fff;
  background-image: none;
}

/* Sidebar Subscribe/Block Buttons */

.sidebar .sub,
.sidebar .sub.pure-button,
.sidebar .unsub,
.sidebar .unsub.pure-button,
.sidebar .unblk,
.sidebar .unblk.pure-button,
.sidebar .blk,
.sidebar .blk.pure-button {
  font-size: 14px;
  border-radius: 2px;
  box-shadow: none;
  text-align: center;
  margin: 0;
  margin-right: 4px;
  color: #fff;
}

.sidebar .sub.button-secondary svg,
.sidebar .sub.button-secondary:hover svg,
.sidebar .unsub.button-secondary svg,
.sidebar .unsub.button-secondary:hover svg,
.sidebar .unblk.pure-button svg,
.sidebar .unblk.pure-button:hover svg,
.sidebar .blk.pure-button svg,
.sidebar .blk.pure-button:hover svg {
  fill: #fff;
}

/* Sidebar Subscribe/Block Buttons Lightmode */

.sidebar .sub,
.sidebar .sub.pure-button {
  background-color: #6c4290;
}

.sidebar .sub:hover,
.sidebar .sub.pure-button:hover {
  background: #369054;
  background-image: none;
}

.sidebar .unsub,
.sidebar .unsub.pure-button {
  background-color: #369054;
}

.sidebar .unsub:hover,
.sidebar .unsub.pure-button:hover {
  background: #6c4290;
  background-image: none;
}

.sidebar .unblk,
.sidebar .unblk.pure-button {
  background-color: #2c62a8;
}

.sidebar .unblk:hover,
.sidebar .unblk.pure-button:hover {
  background: #be3636;
  background-image: none;
}

.sidebar .blk,
.sidebar .blk.pure-button {
  background-color: #be3636;
}

.sidebar .blk:hover,
.sidebar .blk.pure-button:hover {
  background: #2c62a8;
  background-image: none;
}

/* Sidebar Subscribe/Block Buttons Nightmode */

body.dark .sidebar .sub,
body.dark .sidebar .sub.pure-button {
  background-color: #644c7b;
}

body.dark .sidebar .sub:hover,
.body.dark sidebar .sub.pure-button:hover {
  background: #436f51;
  background-image: none;
}

body.dark .sidebar .unsub,
body.dark .sidebar .unsub.pure-button {
  background-color: #436f51;
}

body.dark .sidebar .unsub:hover,
body.dark .sidebar .unsub.pure-button:hover {
  background: #644c7b;
  background-image: none;
}

body.dark .sidebar .unblk,
body.dark .sidebar .unblk.pure-button {
  background-color: #49677f;
}

body.dark .sidebar .unblk:hover,
body.dark .sidebar .unblk.pure-button:hover {
  background: #8a3b3b;
  background-image: none;
}

body.dark .sidebar .blk,
body.dark .sidebar .blk.pure-button {
  background-color: #8a3b3b;
}

body.dark .sidebar .blk:hover,
body.dark .sidebar .blk.pure-button:hover {
  background: #49677f;
  background-image: none;
}

/* Other Buttons Lightmode */

.pure-button-primary,
a.pure-button-primary {
  background-color: #6c4290;
  color: #fff;
}

.pure-button-primary:hover,
a.pure-button-primary:hover {
  background-color: #9251ca;
  color: #fff;
  background-image: none;
}

.button-success,
a.button-success {
  background: #369054;
  color: #fff;
}

.button-success:hover,
a.button-success:hover {
  background: #3eaf60;
  color: #fff;
  background-image: none;
}

.button-error,
a.button-error {
  background: #be3636;
  color: #fff;
}

.button-error:hover,
a.button-error:hover {
  background: #ec3a3a;
  color: #fff;
  background-image: none;
}

.button-warning,
a.button-warning {
  background: #da7a4d;
  color: #fff;
}

.button-warning:hover,
a.button-warning:hover {
  background: #f5844c;
  color: #fff;
  background-image: none;
}

/* Other Buttons Nightmode */

body.dark .pure-button.button-success,
body.dark a.pure-button.button-success {
  background: #436f51;
  color: #fff;
}

body.dark .pure-button.button-success:hover,
body.dark a.pure-button.button-success:hover {
  background: #3eaf60;
  color: #fff;
  background-image: none;
}

body.dark .pure-button.button-error,
body.dark a.pure-button.button-error {
  background: #8a3b3b;
  color: #fff;
}

body.dark .pure-button.button-error:hover,
body.dark a.pure-button.button-error:hover {
  background: #e95151;
  color: #fff;
  background-image: none;
}

body.dark .pure-button.button-warning,
body.dark a.pure-button.button-warning {
  background: #8c4d32;
  color: #fff;
}

body.dark .pure-button.button-warning:hover,
body.dark a.pure-button.button-warning:hover {
  background: #f5844c;
  color: #fff;
  background-image: none;
}

/* Sub List */

td.subsentry.subsubscribed {
  border-left: 4px solid #3eaf60;
}

td.subsentry.blocked {
  border-left: 4px solid #e95151;
}

div.post.admin > div > div > a.authorlink {
  background-color: #da7a4d;
  color: white;
}

div.post.mod > div > div > a.authorlink {
  background-color: #369054;
  color: white;
}

article.text-post.comment.admin > div > div > div > a.poster {
  background-color: #da7a4d;
  color: white;
}

article.text-post.comment.mod > div > div > div > a.poster {
  background-color: #369054;
  color: white;
}

body.dark div.post.admin > div > div > a.authorlink {
  background-color: #8c4d32;
  color: white;
}

body.dark div.post.mod > div > div > a.authorlink {
  background-color: #436f51;
  color: white;
}

body.dark article.text-post.comment.admin > div > div > div > a.poster {
  background-color: #8c4d32;
  color: white;
}

body.dark article.text-post.comment.mod > div > div > div > a.poster {
  background-color: #436f51;
  color: white;
}

div.sidebar.open {
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.6);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.6);
}
body.dark div.sidebar.open {
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.6);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.6);
}
.th-navbar.open {
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.6);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.6);
}
body.dark .th-navbar.open {
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.6);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.6);
}

.flairsettings {
  margin-top: 10px;
  text-align: center;
}

body.dark .th-l-stroke {
  stroke: none;
}

.postflair,
.selflair {
  border: none;
  border-radius: 5px;
  background-color: #e6e6e6;
  color: #333;
}

.user_flair {
  border: none;
  border-radius: 2px;
  background-color: #e6e6e6;
  color: #333;
}

body.dark .postflair {
  background-color: #000;
  border: 1px solid #666;
  color: #666;
}

body.dark .user_flair {
  background-color: #000;
  border: 1px solid #666;
  color: #666;
}

.pollflair {
  padding: 5px;
  margin-right: 0;
  border: none;
  background-color: #e6e6e6;
  color: #333;
}

li.profilebadge {
  height: 120px;
  width: 120px;
  border-radius: 0px;
  margin: 10px;
}

.uprofile-top p {
  margin-top: 0px;
}

body.dark .pollflair {
  background-color: #000;
  border: 1px solid #666;
  color: #666;
}

.pblock ul,
.userrow ul {
  padding: 0px;
}

.uprofile-top {
  text-align: center;
  padding-bottom: 20px;
}

.userrow .pblock {
  text-align: left;
  padding: 0px 15px;
}

.noshit {
  margin: 0 10px 10px;
}
.admin_alert {
    background-color: #DA7A4D;
}

body.dark .admin_alert {
    background-color: #8C4D32;
}

.admin_alert .btn_link {
    color: #000;
}

.message{
background-color: #3EAF60;
}

body.dark .message {
background-color: #436F51;
}

.content {
    padding-top: 4px;
}

.votecomment {
    margin-top: 0;
}

.mod-todo {
	background-color: #FFF3C9;
}

span.ban-string {
    color: black;
    display: inline;
}

.throat-fe-wrapper iframe {
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}
