/* dark mode styles */

/** Logo styles. :/ **/
body.dark .th-l-stroke {
    stroke: var(--dark-primary-color);
}

body.dark .th-l-fill {
    fill: var(--dark-primary-color);
}

body.dark code, body.dark pre{
  background: #161616;
}

body.dark .profilebadge path:not(.nocolor) {
  fill: #686868;
}

body.dark .th-toggle .bar{
  background-color: #666;
}

body.dark {
  background-color: #0a0a0a;
  color: #7d7d7d;
}

body.dark a {
  color: #526a7d;
}

body.dark div.author a {
  color: var(--dark-links-a);
}

body.dark div.author a.authorlink{
  color: #526a7d;
}

body.dark .content,
body.dark #container2 {
  color: #777;
}

body.dark #toggledark i {
    color: #ff0;
}
body.dark .postflair {
  background-color: #000;
  color: #7d7d7d;
  border: 1px solid #1d1d1d;
}
body.dark .user_flair {
  background-color: #0d0d0d;
  border: 1px solid #1d1d1d;
  color: #888;
}
/* Hot, Top, New, Domain */

body.dark div#sortbuttons span {
    color: #ddd;
    background-color: #101010;
}

body.dark div#sortbuttons {
  color: #7b4a3f;
}

body.dark a:hover,
body.dark #sortbuttons a:hover{
  color: #d17262;
}

body.dark .post .domain a,
body.dark .post .domain {
    color: #555;
}

/* Header */

body.dark .th-navbar {
  background-color: #000;
  -webkit-box-shadow: 0 3px 5px 0 rgba(0,0,0,0.75);
  -moz-box-shadow: 0 3px 5px 0 rgba(0,0,0,0.75);
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.75);
}

body.dark .th-navbar.open {
  background-color: #000;
  -webkit-box-shadow: 0 2px 3px 0 var(--dark-shadow);
  -moz-box-shadow: 0 2px 3px 0 var(--dark-shadow);
  box-shadow: 0 2px 3px 0 var(--dark-shadow);
}

/* Sub bar */

body.dark .th-subbar{
  background-color: #000;
  border-bottom: 1px solid var(--dark-shadow);
}

body.dark .th-subbar ul li {
  color:#cdcdcd;
}

body.dark .th-subbar a {
  color: #ccc;
}

body.dark .th-subbar a:hover {
  color: var(--dark-primary-color);
}

/* Posts */

body.dark .comments article.comment.highlight{
  background-color: rgba(64, 64, 61, 0.35);
}
body.dark .post:nth-child(2n) {
  background-color: #0d0d0d;
    -webkit-box-shadow: -3px 2px 4px 0 rgba(0,0,0,0.4);
    -moz-box-shadow: -3px 2px 4px 0 rgba(0,0,0,0.4);
    box-shadow: -3px 2px 4px 0 rgba(0,0,0,0.4);

}

body.dark .votebuttons {
  fill: #7d7d7d;
  color: #7d7d7d;
}

body.dark .post .title {
  color: #ccc;
}

body.dark .post a.title:hover {
  color: var(--dark-primary-color);
}

body.dark .post .title:visited {
  color: var(--dark-links-visited);
  }

body.dark .thumbnail .placeholder svg path {
    fill: #555;
}

body.dark .p-icon[data-icon="exclaim"] path {
    fill: var(--dark-primary-color);
}

/* Buttons */

body.dark .pure-button.button-transparent, body.dark .pure-button.button-transparent:hover {
    background: none;
}

body.dark .button-secondary,
body.dark .pure-button {
  background: rgba(51, 51, 51, 0.5);
  color: #ddd;
}

body.dark .inboxside .button-secondary {
    background: rgba(51, 51, 51, 0.2);
}

body.dark .button-secondary:hover,
body.dark .pure-button:hover {
  background: rgba(51, 51, 51, 0.8);
  color: #ffffff;
}

body.dark .pure-button-primary {
  background-color: #161616;
}

body.dark a#nextp,
body.dark a#prevp {
  color: #c0c0c0;
}

body.dark a#nextp:hover,
body.dark a#prevp:hover {
  color: #ffffff;
}

body.dark .pure-button.button-warning {
  background: #ab1010;
}


/* Expandos */

body.dark .expando {
    background-color: #0a0a0a;
}

body.dark .post .expandotxt,
body.dark .post:nth-child(2n) .expandotxt {
    background-color: #202020;
    color: #aaa;
}

body.dark input[type="text"]:not(.numInput),
body.dark input[type="email"],
body.dark input[type="url"],
body.dark textarea,
body.dark input[type="password"],
body.dark select {
  color: #ffffff;
  background-color: #252525;
  box-shadow: inset 0 1px 3px #303030;
  border-color: #444;
}

body.dark textarea {
  background-color: #000;
}

body.dark{
  color: #666;
}

body.dark .post-content-container, body.dark .poll-space{
  background: #111;
}
body.dark .editbtns{
  border-color: #444;
}

body.dark .editbtns div{
  border-color: #292929;
}

body.dark .editbtns svg path{
  fill: #666;
}

body.dark input[type="text"]::placeholder,
body.dark input[type="email"]::placeholder,
body.dark input[type="url"]::placeholder,
body.dark textarea::placeholder,
body.dark input[type="password"]::placeholder {
  color: #555;
}

body.dark input#buyamount ~ b {
    color: #c2c2c2;
}

/* Sidebar */
body.dark .sidebar{
  background-color: #0d0d0d;
  border-left: solid 1px #000;
}

body.dark .sidebar h4 {
	color: #ccc;
}

body.dark input[type="search"] {
  background-color: #000;
  color: #fff;
  border-color: black;
}

body.dark .flairpicker select {
  background-color: #000;
  color: #fff;
  border-color: black;
}

body.dark .sidebar ul.top5 li a {
    color: var(--dark-links-a);
}

body.dark hr {
   border-bottom: 2px dashed #545454;
}
/* /Sidebar */

/* MESSAGES STYLES */
body.dark .pmessage.post p.title {
    border-bottom: 1px solid #343434;
}
body.dark .pmessage.post {
    background-color: #000000;
}

body.dark .pmessage.post p.container a{
    background-color: rgba(0, 0, 0, 0.2);
}

body.dark .pmessage.post p.container a:hover{
    background-color: rgba(0, 0, 0, 0.5);
    color: #b66f5f;
}

body.dark .pmessage p.container a.deletedmsg {
    background-color: rgba(226, 0, 0, 0.3);
    color: #a8a8a8;
}

body.dark .pmessage p.container a.savedmsg {
    background-color: rgba(0, 170, 9, 0.3);
    color: #a8a8a8;
}

body.dark .pmessage.post:nth-child(2n) {
    background-color: #0d0d0d;
}

body.dark .modal-content,
body.dark #formpop .modal-content {
    background-color: #111;
    border: 1px solid #4d4848;
    box-shadow: 4px 4px 16px #1d1d1d;
}
body.dark .modal-content h3,
body.dark #formpop .modal-content h3,
body.dark #formpop .modal-content p {
    color: #cccccc;
}
body.dark .modal-content .closemsg,
body.dark #formpop .modal-content .closemsg {
    color: #999;
}
/* /MESSAGES STYLES */

/* SUBS PAGE */
body.dark .subsentry svg {
    fill: #fff;
}
body.dark .sbm-icon svg, body.dark .i-icon svg {
    fill: #fff;
}
/* /SUBS PAGE */

/* USER PAGE */
body.dark .pblock.level .inset{
  background-color: #0a0a0a;
}

body.dark .pblock.level{
  background-color: #474747;
}

body.dark .uprofile.content {
    border: 2px solid #474747;
    background: rgba(0, 0, 0, 0.9);
}
body.dark article.comment {
    background-color: #101010;
}
body.dark article.comment:nth-child(2n) {
    background-color: #0d0d0d;
}
/* /USER PAGE */

/* misc */
body.dark span.mailcount {
  background: red;
}
body.dark table td, body.dark table th, body.dark table{
  border-color: #4e4e4e;
}
body.dark table thead{
  background-color: #5c5c5c;
}
body.dark path[stroke="#ff6d15"] {
    stroke: #7b4a3f;
}
body.dark path[fill="#ff6d15"] {
    fill: #7b4a3f;
}
body.dark .mod2-inv{background-color: #060606;}

body.dank div:not(.pure-menu):not(.cw-brand):not(#menu) {
    filter: grayscale(100%);
}

body.dank .th-l-stroke {animation: rb 1s infinite; }
body.dank .th-l-fill {animation: rb2 1s infinite; }
@keyframes rb2{20%{fill: red;}40%{fill: yellow;}60%{fill: green;}80%{fill: blue;}100%{fill: orange;} }
@keyframes rb{20%{stroke: red;}40%{stroke: yellow;}60%{stroke: green;}80%{stroke: blue;}100%{stroke: orange;}}

/* FORM STYLES */

body.dark #login-form, body.dark #post-form, body.dark #csub-form {
  background-color: #101010;
}

/* /FORM STYLES */

/* chat */
body.dark #chbott-fixed #chsend{
  border: 1px solid #333;
}
body.dark #chtitle:hover{
  color: #888;
  background: #000;
}
body.dark #chtitle {
  background: #0d0d0d;
  color: #ccc;
}
body.dark #chpop {
  background: #000;
  border-top: 1px solid var(--dark-primary-color);
  border-left: 1px solid var(--dark-primary-color);
  border-right: 1px solid var(--dark-primary-color);
}
body.dark #chcont {
  border-bottom: none;
  color: #ccc;
}

body.dark #chsend {
    background-color: #0d0d0d;
}

body.dark .msg-hl{
  background-color: #111;
}


/* other stuff? */

body.dark spoiler:not(.shown) a {
  color: #555;
}

body.dark spoiler:not(.shown) {
  color: #555;
  background-color: #555;
}

body.dark .cw-items a{
  color: #fff;
}

body.dark .motto{
  color: #ccc;
}

body.dark .admin_alert{
  background-color: #bd0101;
  color: #dedede;
}

body.dark .admin_alert .btn_link{
  color: #1b006f;
}

body.dark .editbar path {fill: #d25309;}

body.dark #hiddensubs{
  background: #000;
  filter: drop-shadow(-20px 20px 18px #000);
}

body.dark #hiddensubs li:hover{
  background-color: #0a0a0a;
}

body.dark thead a{
  color: #231411
}

body.dark .alldaposts .links a.comments{
  color: #526a7d;
}

body.dark .alldaposts .links a{
  color: #797979;
}


/* poll */
body.dark .poll-pbar {
  background-color: #4a4a4a;
  border-color: #656565;
}

/* modals */
body.dark .tingle-modal-box{
  background-color: #171717;
}

body.dark .bot-tag{
  border: 1px solid #2f2f2f;
  background: #111;
  color: #7d7d7d;
}

/* deleted comments and posts view for mods and admins */
body.dark .mod.report.preview .preview-text-container.deleted {
  background-color: #171717;
  border: 1px solid red;
}

body.dark .mod.report.preview .preview-text-container.deleted.post-content-container {
  background-color: #171717;
  border: 1px solid red;
}

body.dark article.text-post.comment.deleted {
  background-color: #171717;
  border: 1px solid red;
}

body.dark .post-content-container.deleted {
  background-color: #171717;
}

body.dark .postbar.post.deleted {
  background-color: #171717;
  border: 1px solid red;
}

body.dark .poll-space.deleted {
  background-color: #171717;
  border: 1px solid red;
}

body.dark .uploaditem.deleted {
  background-color: #171717;
  border: 1px solid red;
}

body.dark .deleted-user {
  background-color: #171717;
  border: 1px solid red;
}

body.dark .browse-history {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #171717;
  border: 1px solid #d94f00;
  color: lightgray;
}

body.dark .browse-history.disabled {
  border-color: #333;
  color: #171717;
}

body.dark .browse-history.disabled:focus {
    outline: #333 none;
}

body.dark .browse-history:focus {
    outline: #d94f00 none;
}

body.dark .links a.post-open-reports {
    color: #d30404;
}

body.dark #sortby{
    background: #171717;
  color: #ddd;
  font-size: smaller;
  text-align: center;
  cursor: pointer;
}

body.dark .pure-menu-item a{
  background: #171717;
  color: #fff
}

body.dark .pure-menu-item a:hover{
  background: #666;
  color: #d17262;
}

body.dark .pure-menu-children {
    border-color: #333;
}

body.dark .popup {
    background-color: #121212;
    border-color: #0f0f0f;
}

body.dark .selflair, body.dark .submitflair {
    background-color: #333;
    color: #777;
    border-color: #222;
}

body.dark .submitflair.selected {
    color:#ddd;
    background-color: #161616;
    border-color: #666;
}

body.dark .submitflair:hover {
    background-color: #aaa;
}

body.dark .admin-config-edit {
    fill: #526a7d;
}

body.dark .admin-config-edit:hover {
    fill: #d17262;
}

body.dark .mod-table .header {
    border-color: #4e4e4e;
    background-color: #5c5c5c;
}

body.dark .mod-table,
body.dark .mod-table .header,
body.dark .mod-table .elem,
body.dark .mod-table .close-elem,
body.dark .mod-table .last-elem {
    border-color: #4e4e4e;
}

body.dark .mod-table .elem,
body.dark .mod-table .close-elem,
body.dark .mod-table .last-elem {
    background-color: #222;
}
